<template>
  <div>
    <div class="row">
      <div class="col-md-12">
         <KTCodePreview v-bind:title="$t('USERS')">
            <template v-slot:toolbar>
               <b-button  v-b-modal.modal-create-user variant="primary"><i class="fas fa-plus fa-sm"></i>{{ $t('NEW') }}</b-button>
           </template>
          <template v-slot:preview>
            <div>
             <b-table :items="items" 
                      :fields="tableHeaderFields" 
                      :no-local-sorting="true" 
                      :sort-by.sync="sortBy" 
                      :sort-desc.sync="sortDesc" 
                      responsive>
             </b-table> 
             <b-row>
               <b-form-group>
                <b-pagination
                v-model="currentPage"
                :total-rows="totalItems"
                :per-page="perPage"
                @change="onPageChange"
                aria-controls="my-table"
                ></b-pagination>    
               <label>{{ $t('TOTAL') }}: {{ totalItems }}</label>
               </b-form-group>
            </b-row>               
          </div>

 <b-modal id="modal-create-user" @ok="createUser" centered :title="$t('NEW_USER')">
    <div>
      <form role="form">
	<div class="form-group">
		<div class="form-group has-error has-feedback" style="margin-bottom: 5px;">
       <b-form-input
                 class="form-control"
                :placeholder="$t('NAME')"
                 v-model="$v.user.name.$model"
                 :state="validateState('name')"
                 aria-describedby="name-feedback"
        ></b-form-input>    
       <b-form-invalid-feedback id="name-feedback">
              {{ $t('NAME_IS_REQUIRED')}}
       </b-form-invalid-feedback>
		</div>
		<div class="form-group has-error has-feedback" style="margin-bottom: 5px;">
       <b-form-input
                 class="form-control"
                :placeholder="$t('JOB')"
                 v-model="$v.user.job.$model"
                 :state="validateState('job')"
                 aria-describedby="job-feedback"
        ></b-form-input>    
       <b-form-invalid-feedback id="sirName-feedback">
              {{ $t('JOB_IS_REQUIRED')}}
       </b-form-invalid-feedback>
		</div>
		</div>
</form>
</div>
   <template v-slot:modal-footer="{ ok, cancelUserCreation }">
      <b-button size="sm" variant="default" @click="cancelUserCreation()">
        {{ $t('CANCEL') }}
      </b-button>
      <b-button size="sm" variant="primary" @click="ok()">
        {{ $t('CREATE') }}
      </b-button>
    </template>
  </b-modal>


          </template>
        </KTCodePreview>     
            </div>
      </div>
  </div>
</template>

<script>
import ApiService from "@/core/services/api.service";
import KTCodePreview from "@/view/content/CodePreview.vue";

import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";

export default {
  mixins: [validationMixin],
  components: { KTCodePreview },
  data() {
    return {
        tableHeaderFields: [
          {
            key: 'id',
            label: this.$i18n.t('ID'),
            sortable: true
          },
          {
            key: 'email',
            label: this.$i18n.t('EMAIL'),
            sortable: true
          },
          {
            key: 'first_name',
            label: this.$i18n.t('FIRST_NAME'),
            sortable: true
          },
          {
            key: 'last_name',
            label: this.$i18n.t('LAST_NAME'),
            sortable: true
          },
          {
            key: 'avatar',
            label: this.$i18n.t('AVATAR'),
            sortable: true
          }
        ],
        items: [],
        totalItems: 0,
        perPage: 10,
        currentPage : 1,
        user: {
            name: '',
            job: ''
          }
      }
  },
  validations: {
    user: {
      name: {
        required
      },
      job: {
        required
      }
    }
  },
  created() {
     this.fetchData();
  },
  methods: {
    validateState(name) {
      const { $dirty, $error } = this.$v.user[name];
      return $dirty ? !$error : null;
    },
    fetchData() {
        return new Promise(resolve => {
            ApiService.get("api/users?page="+this.currentPage)
              .then(({ data }) => {
                this.items = data.data;
                this.totalItems = data.total;
                resolve(data);
              })
              .catch(() => {
              });    
        });
      },
      onPageChange: function(page) {
         this.currentPage = page;
         this.fetchData();
      },
      createUser: function(e) {
         e.preventDefault();
         this.$v.user.$touch();
         if (this.$v.user.$anyError) {
            return;
         }
         var user = {
                        name: this.user.name,
                        job: this.user.job       
          };
          ApiService.post("api/users", user)
              .then(() => {
                 this.fetchData();
              })
              .catch(() => {
              });
      },
  }
};
</script>
